import React from "react";

import Header from "../../components/Header";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";

import "./styles.css";

import { AppConfig } from "../../App.config.js";
import { AppProvider } from "../../AppProvider";
import VideoDrive from "../../components/VideoDrive";
import { useHistory, useLocation, useParams } from "react-router-dom";

const ICON_SIZE = '2em';


function TelaLogin() {
  return (
    <AppProvider.Context.Consumer>
      {(context) => (
        <div className="login">
          <Header usuario={context.state.usuario} videoajuda="1ZldRbu7iimr7cxpSxB-U_ZDTIPLQf3AI" />
          {renderGoogleLogin(context.state.usuario)}
          {renderUsuarioNaoAutorizado(context.state.usuario, context.state.questionariosModelos)}
        </div>
      )}
    </AppProvider.Context.Consumer>
  );
}

function decidirFrontendHost() {
  return AppConfig.GAS_URL_ACCOUNT_CHOOSER += (AppConfig.DEV_MODE) ? AppConfig.GAS_URL_DEV : AppConfig.GAS_URL_EXEC;
}


/**
 * @description - Aplica a ação de carregar a URL de login Google, concatenada com a do script web em modo dev, caso o usuário
 * não esteja logado e autorizado. 
 * @param {*} usuario 
 */
function renderGoogleLogin(usuario) {
  //usuário não está logado
  if (usuario && !usuario.email && !usuario.autorizado) {
    // console.log("RENDER GOOOGLE LOGIN ------------------------------>");
    window.location.replace(decidirFrontendHost());
  }
}

/**
 * @description - Renderiza um botão para abertura de uma nova aba/janela para autenticação noutra conta, caso o usuário
 * não esteja logado mas não esteja autorizado (ex.: como participante de avaliações ou gerente do aplicativo).
 * @param {*} usuario 
 */
function renderUsuarioNaoAutorizado(usuario, questionariosModelos) {
  //usuário está logado com conta desautorizada
  if (usuario && usuario.email && !usuario.autorizado) {
    let titulo = (
      <div>
        ACESSO DESAUTORIZADO!
      </div>
    );

    let subtitulo = (
      <div>
        Acesso desautorizado para a conta <b>{usuario.email}</b>.
      </div>
    );

    let mapaAmostral = questionariosModelos
      .map(questionarioModelo => {
        return questionarioModelo.filtrosParticipante.segmento.map(segmento => {
          let itemMapa = { 'segmento': segmento }

          Object.entries(questionarioModelo.filtrosParticipante).forEach(filtroEntry => {
            if (filtroEntry[0] === 'segmento')
              return;

            if (!Array.isArray(filtroEntry[1]))
              itemMapa[filtroEntry[0]] = [filtroEntry[1]];
            else
              itemMapa[filtroEntry[0]] = filtroEntry[1];
          })
          return itemMapa;
        })
      })
      .flat()
      .reduce((mapaReduzido, itemMapa, index, array) => {
        let itemMapaReduzido = mapaReduzido.find(itemMapaReduzido => itemMapaReduzido.segmento.includes(itemMapa.segmento));
        if (!itemMapaReduzido) {
          mapaReduzido.push(itemMapa);
          return mapaReduzido;
        }

        Object.entries(itemMapaReduzido).forEach(itemMapaReduzidoEntry => {
          if (itemMapaReduzidoEntry[0] === 'segmento')
            return;
          Array.prototype.push.apply(itemMapaReduzidoEntry[1], itemMapa[itemMapaReduzidoEntry[0]]);
          itemMapaReduzido[itemMapaReduzidoEntry[0]] = itemMapaReduzidoEntry[1].filter((value, index, array) => array.indexOf(value) === index);
        })
        return mapaReduzido;
      }, [])
      .map(itemMapa => (
        <div className="p-d-flex">
          <div className="p-mb-4">
            <p>Se você é <b>{itemMapa.segmento}</b></p>
            {
              Object.entries(itemMapa).filter(entry => entry[0] !== 'segmento').map(entry => (
                <p className="p-ml-6">
                  <i><b>{entry[0]}</b></i> = {entry[1].join(', ou ')}.
                </p>
              ))
            }
            <p className="p-ml-6">
              {
                (itemMapa.segmento === 'DISCENTE') ?
                  <i>Regularmente matriculado em pelo menos uma disciplina, no período letivo atual.</i>
                  : (itemMapa.segmento === 'DOCENTE') ?
                    <i>Com a posse de pelo menos um diário de disciplina, no período letivo atual.</i>
                    : (itemMapa.segmento === 'DOCENTE' || itemMapa.segmento === 'TÉCNICO ADMINISTRATIVO') ?
                      <i>Em efetivo exercício em alguma unidade da instituição.</i>
                      : ''
              }
            </p>
          </div>
        </div>
      ));


    //console.log(mapaAmostral)

    return (
      <div>
        <Card className="p-col p-mb-4"
          title={titulo}
          subTitle={subtitulo}
        />
        <Card className="p-col p-mb-4"
          title="PRIMEIRO, CALMA! CONFIRME 😀✌️"
          subTitle={<div>Verifique se você é realmente um participante apto a participar da avaliação.<br />Neste momento, somente possuem acesso autorizado os participantes que se encaixarem nas seguintes condições:</div>}>
          <br />
          <p>
            {mapaAmostral}
          </p>
        </Card>
        <Card className="p-col p-mb-4"
          title="SEGUNDO, TENTE NOVAMENTE 😀👉"
          subTitle={<div>Se a sua conta de acesso for <b>{usuario.email}</b>, por favor tente acessar novamente este site abrindo uma aba ou janela anônima em seu navegador.
          <br />Clique ou toque para dar play em um dos respectivos vídeos abaixo e veja como.</div>}>
          <div className="p-d-flex p-flex-wrap p-fluid">
            <div className="p-col p-p-4 p-mb-4" style={{minWidth: '600px'}}>
              Se estiver em um <u>COMPUTADOR</u>, com um navegador Chrome, Firefox ou Microsoft Edge...<br />
              <VideoDrive driveFileId="1ZldRbu7iimr7cxpSxB-U_ZDTIPLQf3AI" autoPlay="false"/>
            </div>
            <div className="p-col p-p-4 p-mb-4" style={{minWidth: '600px'}}>
              Se estiver em um <u>SMARTPHONE</u>, com um navegador Chrome...<br />
              <VideoDrive driveFileId="1yRyKYkV94N6-K0wq6ApyFcwRPsIOtYjK" autoPlay="false"/>
            </div>
          </div>
        </Card>
        <Card className="p-col p-mb-2"
          title="TERCEIRO, CONTATE-NOS... 📧"
          subTitle="Se você é realmente um participante apto, mas mesmo por aba ou janela anônima não conseguiu acessar, contate-nos.">
          <p className="p-mb-2">
            Envie um e-mail para <b>{AppConfig.CPA_EMAIL}</b> reportando o que houve.
            <br />Caso seja estudante ou servidor do IFPB, <b>informe também a sua matrícula.</b></p>
        </Card>
      </div>
    );
  }

}

export default TelaLogin;
{/* <Button className="p-button-lg p-mb-4"
label="veja como acessar"
icon="pi pi-youtube"
onClick={() => {
  window.open(decidirFrontendHost(), '_blank', 'noopener,noreferrer');
}}  */}