import React from "react";
import "./styles.css";
import ImgLoading from "../../assets/styles/imgs/ifpb-loading.svg"

export default class Loading extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Loading.loading(false);
    }

    render() {
        return (
            <div>
                <div id="loading">
                    <img id="ifpb-logo" src={ImgLoading}/>
                </div>
            </div>
        );
    }

    static loading(show = true) {
        if (show) {
            document.querySelector('#loading').style.display = 'flex';
            document.querySelector("#ifpb-logo").style.display = 'block';
            //console.log("showing loading...");
        }
        else {
            document.querySelector("#loading").style.display = 'none';
            document.querySelector("#ifpb-logo").style.display = 'none';
            //console.log("hiding loading...");
        }
    }

}