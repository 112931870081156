import Loading from "./components/Loading";


/**@
* @descripton - Classe SINGLETON para acesso a requisições via window.addEventListener('message') ao iframe de um projeto Google Apps Script.
* @summary
*      1.Projeto deve estar implantado como uma 'App da Web', no Goole Apps Script.
*      2. GAS_DOMAIN(string) - deve corresponder ao dominio da URL da src do iframe do projeto Google Apps Script (verificável via busca na ferramenta de inspeção do navegador pelo termo 'script.googleusercontent.com').
* @example GAS.getTnstance() //devolve objeto GAS.
* @author giuseppe.lima@ifpb.edu.br
* @since 26/06/2021 
*/
export default class GAS {

    static _instance = null;
    static GAS_DOMAIN_IFRAME = "*";//"https://n-ca4cgm362sqjoxuzuprc7rrzekcxvqqzhi4jwwy-0lu-script.googleusercontent.com"
    
    constructor() {
        if (GAS._instance != null)
            throw new Error('Esta classe não pode ser instanciada mais de uma vez');
        // if (GAS._instance === null)
        //     throw new Error('Esta classe é um singleton e somente pode ser instanciada via GAS.getInstance()');
        window.removeEventListener('message', this.handleWindowMessage);
        window.addEventListener('message', this.handleWindowMessage);

        this.responses = {};

        console.debug('[GAS] Listener instalado no front-end');
    }

    handleWindowMessage(messageWindowEvent) {
        GAS._instance.callback(messageWindowEvent);
    }

    static getInstance() {
        if (GAS._instance === null) {
            GAS._instance = new GAS();
        }
        return GAS._instance;
    }

    request = async (service, ...serviceParams) => {
        if (window.location !== window.parent.location) {
            let responseIndex = Object.entries(this.responses).length;
            console.debug(`[GAS] Inicializando GAS.request(${service}) no servidor com response nº{${responseIndex}}`);
            let message = {
                functionRunParams: {
                    functionName: service,
                    functionParams: serviceParams
                },
                functionCallbackName: responseIndex //caso não funcione trocar por JSON.stringify(formObject)                
            };
            Loading.loading(true);
            window.parent.postMessage(message, '*');
            return await this.response(responseIndex);
        } else {
            let error = `A app não está carregada dentro de um iframe de projeto GAS, com URL /dev ou /exec`;
            console.error(error);
            throw new Error(error);
        }
    }

    response(responseIndex) {
        this.responses[responseIndex + ''] = {};
        return new Promise((resolve, reject) => {
            let responseIntervalId = setInterval(() => {
                if (this.responses[responseIndex].done) {
                    clearInterval(this.responses[responseIndex].intervalId);
                    let response = this.responses[responseIndex].response;
                    delete this.responses[responseIndex];

                    //não possui mais responses em execução ocultar loading
                    Loading.loading(this.responses.length === 0);

                    if (this.hasCPAError(response)) {
                        // response = new Error(response.mensagem);
                        // console.error(response);
                        reject(new Error(response.message));
                    }
                    resolve(response);

                } else {
                    Loading.loading(this.responses.length !== 0);
                }
                //console.debug("processando response index " + responseIndex);
            }, 500);
            this.responses[responseIndex].done = false;
            this.responses[responseIndex].intervalId = responseIntervalId;
        });
    }

    /**
     * Invocada via iframe pai, quando algo é retornado pelo fim de google.script.run lá na cloud
     * @param e   função a ser chamada no iframe filho
     * @param targetObject parâmetros da função chamada no iframe filho
     */
    callback(e) {
        // if (!e.origin.includes(this.GAS_DOMAIN_IFRAME))
        //     return;

        var functionCallbackName = e.data.functionCallbackName;
        var functionCallbackParams = e.data.functionCallbackParams; //caso não funcione trocar por JSON.parse(functionRunParams)

        console.debug(`[GAS] Inicializando GAS.callback() para response nº{${functionCallbackName}}`);

        if (this.responses[functionCallbackName] !== undefined) {
            this.responses[functionCallbackName].response = functionCallbackParams;
            this.responses[functionCallbackName].done = true;
        }
    }

    hasCPAError(responseObj) {
        return (responseObj instanceof Error && responseObj.message.includes('CPAError'));
    }

}
