import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { ListBox } from 'primereact/listbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { OrderList } from 'primereact/orderlist';
import { Tooltip } from 'primereact/tooltip';

import "./styles.css";
import { Link } from 'react-router-dom';


export const FormikFormTomadasAcoes = (props) => {
    const MAX_CARACTERES = 500;
    const FIELD_TOOLTIP_OPTIONS = { position: 'top' };
    const [editAcao, setEditAcao] = useState(null);
    // const [editButtonEnabled, setEditButtonEnabled] = useState(false);
    const [acoes, setAcoes] = useState([]);
    const [impedimento, setImpedimento] = useState(null);
    const [comprovacao, setComprovacao] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [editFieldItemValue, setEditFieldItemValue] = useState(null);

    // avaliacao: 0,
    //                     qual: null,
    //                     como: null,
    //                     impedimentos: [

    //                     ],
    //                     comprovacoes: [

    //                     ]


    useEffect(() => {
        setAcoes(props.topico.acoes);
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            acao: editAcao && editAcao.acao || '',
            avaliacao: editAcao && (editAcao.avaliacao >= 0) ? editAcao.avaliacao : '',
            impedimentos: editAcao && editAcao.impedimentos || [],
            comprovacoes: editAcao && editAcao.comprovacoes || []
        },
        validate: (data) => {
            const ignorarFormikFields = ['impedimentos', 'comprovacoes'];
            let errors = {};

            Object.entries(formik.initialValues).forEach(formikField => {
                if ((true && data[formikField[0]] && !data[formikField[0]].length) || !data[formikField[0]]) {
                    //casos a ignorar 
                    if (typeof data[formikField[0]] === 'number'
                        || ignorarFormikFields.includes(formikField[0]))
                        return
                    errors[formikField[0]] = 'Campo obrigatório.';
                }
            });
            // console.log(errors);
            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setShowMessage(true);
            formik.resetForm();



            if (editAcao) {
                console.log('submit')
                editAcao.acao = data.acao;
                editAcao.avaliacao = data.avaliacao;
                editAcao.impedimentos = data.impedimentos.filter(impedimento => true && impedimento && impedimento.length).map(impedimento => impedimento.trim());
                editAcao.comprovacoes = data.comprovacoes.filter(comprovacao => true && comprovacao && comprovacao.length).map(comprovacao => comprovacao.trim());
                editAcao.revisao = {
                    data: new Date().toJSON(),
                    usuario: "giuseppe.lima@ifpb.edu.br"
                }
                if (!acoes.includes(editAcao)) {
                    acoes.push(editAcao)
                }

            }

            // console.log(editAcao)
            // console.log(acoes)
            // console.log(formik.dirty)

            //seta form de edição de ação para desaparecer no render
            setEditAcao(null);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error p-d-flex">{formik.errors[name]}</small>;
    };

    // const LISTBOX_OPTIONS_ACOES = props.dropdownOptions.avaliacao.map((item, indexItem) => {
    //     return {
    //         label: item.label, code: indexItem,
    //         items: props.topico.acoes.filter(acao => acao.avaliacao === indexItem).map((acao) => {
    //             return { label: acao.acao, value: acao }
    //         })
    //     }
    // });

    const itemTemplateAcao = (acao) => {
        return (
            <React.Fragment>
                <Tooltip target={`.tooltip-acao-${acoes.indexOf(acao)}`}>
                    <div className='p-p-2'>
                        <small style={{ overflowWrap: 'anywhere' }}>
                            <span className='p-text-bold'>REVISADO EM</span>
                            <div>{new Date(acao.revisao.data).toLocaleString()}</div>
                            <div className='p-text-italic p-mb-1'>{acao.revisao.usuario}</div>
                            <div className="">
                                <i className="pi pi-flag-fill " /> {acao.impedimentos.length} impedimentos
                            </div>
                            <div className="">
                                <i className="pi pi-bookmark-fill" /> {acao.comprovacoes.length} comprovações
                            </div>
                        </small>
                    </div>
                </Tooltip>
                <div className={`p-d-flex p-ai-center tooltip-acao-${acoes.indexOf(acao)}`} data-pr-position='top' data-pr-mousetrack="true" /*onClick={(e) => { setEditButtonEnabled(true) }}*/
                    onClick={() => {
                        // let elementSelected = document.querySelector('.formik-form-tomadasacoes .p-orderlist-item.p-highlight');
                        // console.log(elementSelectedIndex)
                        let elementSelectedIndex = acoes.indexOf(acao);
                        if (elementSelectedIndex === acoes.indexOf(editAcao))
                            return;

                        // formik.submitForm().then(() => {
                        setEditAcao(acoes[elementSelectedIndex]);
                        // });
                    }}>
                    <div className="p-col-1 p-p-1">
                        <h1>{acoes.indexOf(acao) + 1}</h1>
                    </div>
                    <div className="p-col-10">
                        <div>{acao.acao}</div>
                        <span className={`badge-topico ${(acao.avaliacao === props.dropdownOptions.avaliacao.length - 1) ? 'concluso' : 'inconcluso'}`}>{props.dropdownOptions.avaliacao[acao.avaliacao].label}</span>
                    </div>
                </div >
            </React.Fragment>
        );
    }

    const headerAcoes = (
        <div className="p-d-flex">
            <dir className="p-col-6">
                <Button type="button" tooltipOptions={{ position: 'bottom' }} label="AÇÃO" tooltip="Adiciona uma ação na lista deste tópico" icon="pi pi-plus" onClick={() => {
                    setEditAcao({});
                }} />
            </dir>
            {/* <Button disabled={!editButtonEnabled} tooltipOptions={{ position: 'bottom' }} tooltip="Edita a ação selecionada na lista abaixo" icon="pi pi-pencil" onClick={() => {
                let elementSelected = document.querySelector('.formik-form-tomadasacoes .p-orderlist-item.p-highlight');
                let elementSelectedIndex = Array.from(elementSelected.parentElement.children).indexOf(elementSelected);
                setEditAcao(acoes[elementSelectedIndex]);
            }} /> */}
            <dir className="p-col-6 p-d-flex p-jc-end">
            <Button className="p-mr-2" type="button" disabled={!formik.dirty} tooltipOptions={{ position: 'bottom' }} tooltip="Edita a ação selecionada na lista abaixo" icon="pi pi-save" onClick={() => {
                formik.submitForm();
            }} />
            <Button type="button" disabled={!editAcao} className="p-button-danger" tooltipOptions={{ position: 'bottom' }} tooltip="Remove a ação selecionada da lista abaixo" icon="pi pi-trash" onClick={() => {
                let acoesRestantes = acoes.filter(acao => acao !== editAcao);
                setAcoes(acoesRestantes);
                setEditAcao(null);
            }} />
            </dir>
        </div>
    )

    const renderAcoes = /*(editAcao !== null) ? '' :*/ (
        <form id={props.id} className="p-d-flex p-dir-col" onSubmit={(e) => { e.preventDefault(); props.topico.acoes = acoes; props.handleSubmit(props.topico) }}>
            <div className="p-field">
                <label htmlFor="acoes">AÇÕES</label>
                {/* <label htmlFor="acoes" className="p-d-block p-justify-end">Ações</label> */}
                <OrderList name="acoes" value={acoes} itemTemplate={itemTemplateAcao} header={headerAcoes} onChange={(e) => {
                    setAcoes(e.value);
                }} />
            </div>
        </form>
    );

    const listboxOptionsFromField = (field) => {
        return formik.values[field] && formik.values[field].map((item, itemIndex) => {
            // console.log('option', impedimento, indexImpedimento)
            return {
                label: item,
                value: itemIndex
            }
        });
    }

    const renderFieldTextURL = (textValue) => {
        const REGEX_URL_ṔATTERN = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        let textComLinks = textValue.split(/\s/);
        // console.log(textComLinks)
        return textComLinks.map((palavra, index) => {
            return (REGEX_URL_ṔATTERN.test(palavra)) ? <Button style={{ padding: '0px', width: 'auto' }} className="p-button-link" href="#" onClick={(e) => window.open(palavra)} label={palavra} /> : palavra;
        }).reduce((renderResult, item) => <>{renderResult} {item}</>)
    }

    const renderItemTemplateByFormikField = (option, field, stateFieldIndex) => {
        const handleEditItem = (e) => {
            //dispõe valor do texto do item para <InputTexarea: de edição
            setEditFieldItemValue(option.label);

            //seleciona o item para exibição do <InputTexarea> de edição
            if (field === 'impedimentos') {
                setImpedimento(option.value);
            } else if (field == 'comprovacoes') {
                setComprovacao(option.value);
            }
        };

        const handleSaveItem = (e) => {

            //aplica valor do <InputTextarea> no campo formik
            let newValue = [].concat(formik.values[field]);
            newValue[option.value] = editFieldItemValue || ''; //se valor deixado vazio, dispor string vazia para evitar erro no formik
            formik.setFieldValue(field, newValue);

            //limpa valor de campo de edição <InputTextarea>
            setEditFieldItemValue(null);

            //seta item em edição como null, para o render retirar o <InputTextarea>.
            if (field === 'impedimentos') {
                setImpedimento(null);
            } else if (field == 'comprovacoes') {
                setComprovacao(null);
            }

        };

        let editando = (stateFieldIndex === option.value);
        // console.log(editando)
        let optionId = `edit-${field}-${option.value}`;
        return (
            <React.Fragment>
                <div className="p-grid p-ai-center p-jc-center">
                    <div className="p-col-1">
                        <h1> {option.value + 1} </h1>
                    </div>
                    <div className="p-col-11">
                        {
                            (editando) ?
                                <InputTextarea
                                    key={optionId}
                                    maxLength={MAX_CARACTERES}
                                    // ids={field}
                                    name={optionId}
                                    value={editFieldItemValue || '' /*formik.values[field][option.value]*/}
                                    style={{ width: '100%' }}
                                    // tooltip=""
                                    // tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('acao') })}
                                    autoFocus
                                    autoResize
                                    onChange={(e) => {
                                        setEditFieldItemValue(e.target.value);
                                        // let newValue = [].concat(formik.values[field]);
                                        // newValue[option.value] = e.target.value;
                                        // formik.setFieldValue(field, newValue, false);
                                        // formik.setFieldTouched(field, true);
                                        // document.querySelector(`textarea ~ div small.textcounter`).innerHTML = `${(MAX_CARACTERES - e.target.value.length)} de ${MAX_CARACTERES} caracteres restantes`;
                                    }}

                                    onBlur={handleSaveItem /*aplica alterações se sair da coluna de edição*/}

                                /> : renderFieldTextURL(formik.values[field][option.value])
                        }
                    </div>
                    <div className="p-col p-d-flex p-jc-end">
                        <Button type="button" className="p-button-rounded p-button-text p-button-icon-only p-button-plain" tooltipOptions={{ position: 'top' }} tooltip="Editar"
                            icon={(editando) ? 'pi pi-check' : 'pi pi-pencil'}
                            onClick={(e) => {

                                if (editando)
                                    handleSaveItem(e);
                                else
                                    handleEditItem(e);

                                // if (field === 'impedimentos') {
                                //     (editando) ? setImpedimento(null) : setImpedimento(option.value);
                                // } else if (field == 'comprovacoes') {
                                //     if (editando) {
                                //         let newValue = [].concat(formik.values[field]);
                                //         newValue[option.value] = editFieldItemValue;
                                //         formik.setFieldValue(field, newValue);
                                //         setComprovacao(null);
                                //     } else {
                                //         setEditFieldItemValue(option.label);
                                //         setComprovacao(option.value);
                                //     }
                                // }

                            }} />
                        <Button type="button" className="p-button-rounded p-button-text p-button-icon-only p-button-danger" tooltipOptions={{ position: 'top' }} tooltip="Remover" icon="pi pi-trash" onClick={() => {
                            formik.setFieldValue(field, formik.values[field].filter((item, itemIndex) => {
                                // console.log('imp',impedimento, indexImpedimento, option.value)
                                return itemIndex !== option.value;
                            }));
                        }} />
                    </div>
                </div>
            </React.Fragment>
        )
    };

    const renderFormEditAcao = (editAcao === null) ? '' : (
        <React.Fragment>
            {/* <div className="p-col">
                    <Button type="button" tooltipOptions={{ position: 'bottom' }} label="VOLTAR" tooltip="Retorna para a lista de ações" icon="pi pi-arrow-left" onClick={() => {
                        setEditButtonEnabled(false);
                        setEditAcao(null);
                        setImpedimento(null);
                        setComprovacao(null);
                    }} />
                </div> */}
            <form /*id={props.id}*/ className="p-d-flex p-dir-col p-fluid" onSubmit={formik.handleSubmit}>
                <div className="p-col">
                    <div className="p-field">
                        <label htmlFor="acao" className="p-d-block p-justify-end">Ação*</label>
                        <InputTextarea
                            maxLength={MAX_CARACTERES}
                            ids="acao"
                            name="acao"
                            value={formik.values.acao}
                            style={{ width: '100%' }}
                            tooltip="Determine o tópico numa frase relativa ao fato ou resultado constatado em avaliações internas e externas institucionais/curso ou de reivindicações da comunidade."
                            tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                            className={classNames({ 'p-invalid': isFormFieldValid('acao') })}
                            autoFocus
                            autoResize
                            onChange={(e) => {
                                formik.handleChange(e);
                                document.querySelector(`textarea ~ div small.textcounter`).innerHTML = `${(MAX_CARACTERES - e.target.value.length)} de ${MAX_CARACTERES} caracteres restantes`;
                            }} />
                        <div><small className="textcounter p-d-block" /></div>
                        <div>{getFormErrorMessage('acao')}</div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-field">
                        <label htmlFor="avaliacao" className="p-d-block">Avaliação*</label>
                        <Dropdown
                            id="avaliacao"
                            name="avaliacao"
                            value={formik.values.avaliacao}
                            options={props.dropdownOptions.avaliacao}
                            className={classNames({ 'p-invalid': isFormFieldValid('avaliacao') })}
                            tooltip="Avalie a ação, indicando o seu estado atual"
                            tooltipOptions={props.dropdownOptions.avaliacao}
                            onChange={formik.handleChange} />
                        {getFormErrorMessage('avaliacao')}
                    </div>
                </div>
                <div className="p-col p-grid">
                    <div className="p-field p-col">
                        <div className="p-mb-2 p-d-flex p-ai-center">
                            <label htmlFor="acao" className="p-d-block p-justify-end p-mr-2">Comprovações</label>
                            <Button type="button" className="p-button-rounded p-button-outlined p-button-icon-only" tooltip="Adiciona uma comprovação que atesta a realização desta ação" icon="pi pi-plus" onClick={(e) => {
                                formik.setFieldValue('comprovacoes', formik.values.comprovacoes.concat([''])); //força a re-renderizar o form com os valores correntes
                                setEditFieldItemValue(null);
                                setComprovacao(formik.values['comprovacoes'].length);
                            }} />
                        </div>
                        <small>Adicione comprovações que atestam o grau de consolidação da ação (ofícios, atas, portarias ou links).</small>
                        <ListBox key="comprovacoes" /*filter*/ style={{ height: "50vh", overflowY: 'auto' }} itemTemplate={(option) => renderItemTemplateByFormikField(option, 'comprovacoes', comprovacao)} options={listboxOptionsFromField('comprovacoes')} optionLabel="label" value={comprovacao} onChange={(e) => {
                            // setComprovacao(e.value);
                        }} />
                    </div>
                    <div className="p-field p-col">
                        <div className="p-mb-2 p-d-flex p-ai-center">
                            <label htmlFor="acao" className="p-d-block p-justify-end p-mr-2">Impedimentos</label>
                            <Button type="button" className="p-button-rounded p-button-outlined p-button-icon-only" tooltip="Adiciona um impedimento nesta ação" icon="pi pi-plus" onClick={(e) => {
                                formik.setFieldValue('impedimentos', formik.values.impedimentos.concat([''])); //força a re-renderizar o form com os valores correntes
                                setEditFieldItemValue(null);
                                setImpedimento(formik.values['impedimentos'].length);
                            }} />
                        </div>
                        <small>Adicione justificativas no caso de avaliação inconclusa, adicionando fatos que prejudicaram a sua consolidação.</small>
                        <ListBox key="impedimentos" /*filter*/ style={{ height: "50vh", overflowY: 'auto' }} itemTemplate={(option) => renderItemTemplateByFormikField(option, 'impedimentos', impedimento)} options={listboxOptionsFromField('impedimentos')} optionLabel="label" value={impedimento} onChange={(e) => {
                            // setImpedimento(e.value);
                        }} />
                    </div>
                </div>
            </form>
        </React.Fragment>
    )

    // const renderButtonVoltarAcoes = (!editAcao) ? '' : (
    //     <Button type="button" className="p-button-info p-mb-3" tooltipOptions={{ position: 'bottom' }} label="VOLTAR PARA AÇÕES" tooltip="Retorna para a lista de ações" icon="pi pi-arrow-left" onClick={() => {
    //         setEditButtonEnabled(false);
    //         setEditAcao(null);
    //         setImpedimento(null);
    //         setComprovacao(null);
    //     }} />
    // );

    return (
        <div className="formik-form-tomadasacoes p-d-flex">
            <div className="p-col-4">
                {props.header}
                {/* {renderButtonVoltarAcoes} */}
                {renderAcoes}
            </div>
            <div className="p-col-8" style={{ background: 'var(--surface-c)' }}>
                {renderFormEditAcao}
            </div>
        </div>
    );

}

