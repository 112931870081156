import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Message } from 'primereact/message';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";



export const FormikFormTopico = (props) => {
    const MAX_CARACTERES = 500;
    const FIELD_TOOLTIP_OPTIONS = { position: 'right' };
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        // countryservice.getCountries().then(data => setCountries(data));
    }, []);

    const formik = useFormik({
        initialValues: {
            topico: props.topico.topico || '',
            segmentos: props.topico.contexto && props.topico.contexto.segmentos || null,
            nivelOrganizacional: props.topico.contexto && props.topico.contexto.nivelOrganizacional || '',
            sentimento: props.topico.sentimento || '',
            origem: props.topico.origem || ''
        },
        validate: (data) => {
            let errors = {};

            Object.entries(formik.initialValues).forEach(formikField => {
                if ((true && data[formikField[0]] && !data[formikField[0]].length) || !data[formikField[0]]) {
                    errors[formikField[0]] = 'Campo obrigatório.';
                }
            });
            // console.log(errors);
            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setShowMessage(true);
            formik.resetForm();

            props.topico.topico = data.topico;
            props.topico.origem = data.origem;
            props.topico.sentimento = data.sentimento;

            props.topico.contexto = (!props.topico.contexto) ? { nivelOrganizacional: null, segmentos: null } : props.topico.contexto;
            props.topico.contexto.nivelOrganizacional = data.nivelOrganizacional;
            props.topico.contexto.segmentos = data.segmentos;
            props.handleSubmit(props.topico);

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="formik-form-topico">
            <form id={props.id} className="p-fluid" onSubmit={formik.handleSubmit}>
                <div className="p-grid" style={{ height: '100%' }}>
                    <div className="p-field p-col p-mb-6">
                        <label htmlFor="topico" className="p-d-block p-justify-end">Tópico*</label>
                        <InputTextarea
                            maxLength={MAX_CARACTERES}
                            id="topico"
                            name="topico"
                            value={formik.values.topico}
                            style={{ minHeight: '90%' }}
                            tooltip="Determine o tópico numa frase relativa ao fato ou resultado constatado em avaliações internas e externas institucionais/curso ou de reivindicações da comunidade."
                            tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                            className={classNames({ 'p-invalid': isFormFieldValid('topico') })}
                            autoFocus
                            autoResize
                            onChange={(e) => {
                                formik.handleChange(e);
                                document.querySelector(`textarea ~ div small.textcounter`).innerHTML = `${(MAX_CARACTERES - e.target.value.length)} de ${MAX_CARACTERES} caracteres restantes`;
                            }} />
                        <div><small className="textcounter p-d-block" /></div>
                        <div>{getFormErrorMessage('topico')}</div>
                    </div>
                    <div className="p-col">
                        <div className="p-field">
                            <label htmlFor="origem" className="p-d-block">Origem*</label>
                            <Dropdown
                                id="origem"
                                name="origem"
                                value={formik.values.origem}
                                options={props.dropdownOptions.origem}
                                className={classNames({ 'p-invalid': isFormFieldValid('origem') })}
                                tooltip="Indique o tipo de fonte do tópico avaliativo"
                                tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                                onChange={formik.handleChange} />
                            {getFormErrorMessage('origem')}
                        </div>
                        <div className="p-field">
                            <label htmlFor="sentimento" className="p-d-block">Sentimento*</label>
                            <Dropdown
                                id="sentimento"
                                name="sentimento"
                                value={formik.values.sentimento}
                                options={props.dropdownOptions.sentimento}
                                className={classNames({ 'p-invalid': isFormFieldValid('sentimento') })}
                                tooltip="Indique o tipo de sentimento em relação ao tópico."
                                tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                                onChange={formik.handleChange} />
                            {getFormErrorMessage('sentimento')}
                        </div>
                        <div className="p-field">
                            <label htmlFor="nivelOrganizacional" className="p-d-block">Nível Organizacional*</label>
                            <Dropdown
                                id="nivelOrganizacional"
                                name="nivelOrganizacional"
                                value={formik.values.nivelOrganizacional}
                                options={props.dropdownOptions.nivelOrganizacional}
                                className={classNames({ 'p-invalid': isFormFieldValid('nivelOrganizacional') })}
                                tooltip="Indique o nível organizacional de abrangêcia deste tópico."
                                tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                                onChange={formik.handleChange} />
                            {getFormErrorMessage('nivelOrganizacional')}
                        </div>
                        <div className="p-field">
                            <label htmlFor="segmentos" className="p-d-block">Segmentos*</label>
                            <MultiSelect id="segmentos"
                                display="chip"
                                value={formik.values.segmentos}
                                options={props.dropdownOptions.segmentos}
                                className={classNames({ 'p-invalid': isFormFieldValid('segmentos') })}
                                tooltip="Marque os segmentos institucionais que são diretamente impactados pelo tópico."
                                tooltipOptions={FIELD_TOOLTIP_OPTIONS}
                                onChange={formik.handleChange} />
                            {getFormErrorMessage('segmentos')}
                        </div>
                    </div>
                    {/* <Button type="submit" label="Submit" className="p-mt-2" /> */}
                </div>
            </form>
        </div>
    );

}
