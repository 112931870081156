import React, { useState, Component } from "react";

import { Route } from 'react-router-dom'

import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { RadioButton } from 'primereact/radiobutton';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

import { FormikFormTopico } from "../Topico";
import { FormikFormTomadasAcoes } from "../TomadasAcoes";

import "./styles.css";

export default class PainelApropriador extends React.Component {
    /**
     * @summary Inicialização de estado mínima para renderização do cpmponente.
     */
    state = {
        painel: undefined,
        metamodeloAvaliativo: undefined,
        filtroTopicosSentimento: null, //dropdown sentimento
        filtroDimensoes: null, //listbox de dimensões
        filtroEixos: null, //new de topicos
        filtroTopicosText: null,
        dialogConcordar: true,
        dialogConfirmar: false,
        dialogConfirmarFunction: undefined,
        dialogEditarTopico: false,
        dialogEditarTomadasAcoes: false,
        editTopico: undefined,
    }

    itemsTopicosTipos = [
        {
            label: 'TODOS',
            value: null,
        },
        {
            label: 'FRAGILIDADE',
            value: 'FRAGILIDADE'
        },
        {
            label: 'POTENCIALIDADE',
            value: 'POTENCIALIDADE'
        }
    ]

    /**
     * @summary 1º na chamada de montagem do componente
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.renderActionsTopico = this.renderActionsTopico.bind(this);
        this.onChangeSentimento = this.onChangeSentimento.bind(this);
        this.onInputTopico = this.onInputTopico.bind(this);

    }

    /**
     * @summary 2º na chamada de montagem do componente
     * @param {*} props 
     * @param {*} state 
     * @returns {*} - Null caso o repasse de valores props não afete o estado do componente. Caso afete o estado, retorna o objeto de parâmetro de entrada de this.setState()
     */
    static getDerivedStateFromProps(props, state) {
        let modified = {};
        if (props.painel !== state.painel) {
            modified.painel = props.painel;
        }
        if (props.metamodeloAvaliativo !== state.metamodeloAvaliativo) {
            modified.metamodeloAvaliativo = props.metamodeloAvaliativo;
        }
        return (Object.entries(modified).length === 0) ? null : modified;
    }

    componentDidMount() {
    }

    /**
      * @summary 3º na chamada de montagem do componente.
      * @description Elabora o JSX que define o componente.
      * @returns HTML{JSX} - relativo a este componente
      */
    render() {
        let renderDialog, renderDimensoes, renderTopicos;
        if (this.state.dialogConcordar) {
            renderDialog = this.showDialogConcordanciaGerenciar();
        } else if (this.state.dialogEditarTopico) {
            renderDialog = this.showDialogEditarTopico();
        } else if (this.state.dialogEditarTomadasAcoes) {
            renderDialog = this.showDialogEditarTomadasAcoes();
        } else if (this.state.dialogConfirmar) {
            renderDialog = this.showDialogConfirmacao();
        } else {
            // console.log(this.state.etapa)
            let titulo = (
                <div>
                    <i className="pi pi-star" /> {this.state.painel.nivelOrganizacional['CAMPUS']}
                    <br />
                    <i className="pi pi-sitemap" /> {this.state.painel.nivelOrganizacional['CURSO']}
                </div>
            );
            renderDimensoes = (
                <div>
                    <Splitter>
                        <SplitterPanel size={20}>
                            <Card className="disponivel"
                                key={this.state.painel.id}
                                title={titulo}
                                subTitle={`DISPONÍVEL ATÉ ${new Date(this.state.painel.terminoAplicacao).toLocaleString()}`} />
                            <ListBox options={this.renderOptionsDimensoes()} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" value={this.state.filtroDimensoes} onChange={(e) => {
                                let eixoSelecionado = this.state.metamodeloAvaliativo.eixos.find(eixo => eixo.dimensoes.find(dimensao => dimensao = e.value));
                                this.setState({ filtroDimensoes: e.value, filtroEixos: eixoSelecionado })
                            }} />
                        </SplitterPanel>
                        <SplitterPanel size={80} minSize={50}>
                            {/* {this.renderDimensaoDescricao()} */}
                            {/* {this.renderFiltrosTopicos()} */}
                            {this.renderTopicos()}
                        </SplitterPanel>
                    </Splitter>
                </div >
            )
        }

        return (
            <div className="painel-apropriador">
                {renderDialog}
                {renderTopicos}
                {renderDimensoes}
            </div>
        )
    }

    /**
     * @summary 4º na chamada de montagem do componente.
     * @description Funções/ações cuja lógica exija que o JSX do componente já esteja inserido na árvore DOM do navegador somente deve ser chamadas aqui.
     * @example 
     * 1. É invocado imediatamente após um componente ser montado (inserido na árvore). 
     * 2. Inicializações que exijam nós do DOM devem vir aqui. Se precisar carregar data de um endpoint remoto, este é um bom lugar para instanciar sua requisição.
     * 3. Este método é um bom lugar para colocar qualquer subscrição. Se fizer isto, não esqueça de desinscrever no componentWillUnmount().
     */
    componentDidMount() {

    }

    renderDimensaoDescricao() {
        if (this.state.filtroDimensoes !== null)
            return (
                <div className="dimensao-descricao">
                    <b>{this.state.filtroDimensoes.nome}</b>
                    <br />
                    {this.state.filtroDimensoes.descricao}
                </div>
            );
    }

    filterTopicosByState() {
        let topicos = [];
        if (this.state.filtroDimensoes !== null)
            topicos = this.state.painel.topicos.filter(topico => topico.dimensaoNumero === this.state.filtroDimensoes.numero);;
        // if (this.state.filtroTopicosSentimento !== -1)
        //     topicos = topicos.filter(topico => topico.sentimento === this.state.filtroTopicosSentimento);
        // if (this.state.filtroTopicosText !== null && this.state.filtroTopicosText !== '') {
        //     topicos = topicos.filter(topico => { return new RegExp(`${this.state.filtroTopicosText}`, 'gi').test(JSON.stringify(topico)) })
        // }
        return topicos;
    }

    onChangeSentimento(e) {
        this.dt.filter(e.value, 'sentimento', 'equals');
        this.setState({ filtroTopicosSentimento: e.value })
    }

    onInputTopico(e) {
        this.dt.filter(e.target.value, 'topico', 'contains');
        this.setState({ filtroTopicosText: e.target.value });
    }

    renderTopicos() {
        if (this.state.painel === undefined || this.state.filtroDimensoes === null || this.state.filtroDimensoes === null)
            return;

        let topicos = this.filterTopicosByState();

        const header = (
            <div className="p-d-flex p-jc-between">
                <Button className="p-button-outlined" /*icon="pi pi-caret-down"*/ label={`⁉️ DICAS DIMENSÃO ${this.state.filtroDimensoes.numero}`} onClick={(e) => this.op.toggle(e)} aria-haspopup aria-controls="overlay-dica" />
                <OverlayPanel ref={(el) => this.op = el} id="overlay-dica" style={{ width: '50%' }}>
                    <p className="p-mb-4">
                        <h3>|DIMENSÃO {this.state.filtroDimensoes.numero}| {this.state.filtroDimensoes.nome.toUpperCase()}</h3>
                        {this.state.filtroDimensoes.descricao}
                    </p>
                    <p className="p-mb-4">
                        <h3>DICAS PREENCHIMENTO</h3>
                        <ol style={{ '--space-between': '1.5rem', listStylePosition: 'inside', display: 'flex', flexDirection: 'column' }}>
                            <li>Adicione tópicos avaliativos nesta dimensão, que podem ser FRAGILIDADES ou POTENCIALIDADES (em seu CURSO, CAMPUS ou INSTITUIÇÃO).</li>
                            <li>Cada tópico avaliativo adicionado equivale a uma informação apropriadora que mereça destaque na avaliação institucional. Portanto, analise os
                                dados disponíveis das seguintes fontes: relatórios de avaliações realizadas ou em andamento realizadas pela instituição; última avaliação realizadas pelo INEP; ou reúna reivindicações emanadas de reuniões/assembleias realizadas a comunidade. </li>
                            <li>👉 Ao adicionar tópicos como FRAGILIDADE: disponha uma ou mais ações de melhorias sobre eles, que serão monitoradas para superá-los.</li>
                            <li>👉 Ao adicionar tópicos como POTENCIALIDADE: recomenda-se dispoor uma ou mais ações para compartilhá-los ou divulgá-los com a comunidade interna ou externa.</li>
                            <li>⚠️É recomendável que a CPA da instituição ou a SPA de sua unidade oriente sobre como obter fontes de dados para adicionar tópicos avaliativos apropriadamente.
                                Logo que sua lista de tópicos estiver pronta, recomenda-se revisá-la com o NDE de seu curso (ou equivalente) e a SPA de sua unidade.</li>
                        </ol>
                    </p>
                </OverlayPanel>
                <Button tooltipOptions={{ position: 'bottom' }} label="TÓPICO" tooltip="Adiciona um tópico na lista desta dimensão avaliativa" icon="pi pi-plus" onClick={() => {
                    this.setState({ dialogEditarTopico: true, editTopico: { editIndex: false } });
                }} />
            </div>
        );

        const filterTopico = (
            <div className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText style={{ width: "100%" }} type="search" value={this.state.filtroTopicosText || ''} onInput={this.onInputTopico} placeholder="buscar..." />
            </div>
        );

        const filterSentimento = (<Dropdown showClear value={this.state.filtroTopicosSentimento} options={this.itemsTopicosTipos} onChange={this.onChangeSentimento} />);

        let renderAbrangencia = (topico) => {
            return (
                <div>
                    <Tooltip target=".tooltip-abrangencia" />
                    <i className="tooltip-abrangencia pi pi-star" data-pr-tooltip="nível organizacional do tópico" /> {topico.contexto.nivelOrganizacional}
                    <br />
                    <i className="tooltip-abrangencia pi pi-users" data-pr-tooltip="segmentos relacionados ao tópico" /> {topico.contexto.segmentos.join(', ')}
                </div>
            );
        };

        let renderTopico = (topico, rowData) => {
            return (
                <div>
                    <Tooltip target={`.tooltip-historico-${rowData.rowIndex}`}>
                        <div className='p-p-2'>
                            <small style={{ overflowWrap: 'anywhere' }}>
                                <span className='p-text-bold'>CRIADO EM</span>
                                <div>{new Date(topico.criacao.data).toLocaleString()}</div>
                                <div className='p-text-italic p-mb-1'>{topico.revisao.usuario}</div>
                                <span className='p-text-bold'>REVISADO EM</span>
                                <div>{new Date(topico.revisao.data).toLocaleString()}</div>
                                <div className='p-text-italic p-mb-1'>{topico.revisao.usuario}</div>

                            </small>
                        </div>
                    </Tooltip>
                    {/* <Tooltip target=".tooltip-historico" style={{ overflowWrap: 'break-word', fontSize: '0.8rem' }}>
                        <i className="tooltip-historico pi pi-arrow-circle-right" /> <span>{new Date(topico.criacao.data).toLocaleString()}</span> <div className="p-text-italic">{topico.criacao.usuario}</div>
                        <br />
                        <i className="tooltip-historico pi pi-arrow-circle-down" /> <span>{new Date(topico.revisao.data).toLocaleString()}</span> <div className="p-text-italic">{topico.criacao.usuario}</div>
                    </Tooltip> */}
                    <div className={`tooltip-historico-${rowData.rowIndex}`} data-pr-position='right' data-pr-mousetrack="true">{topico.topico}</div>
                </div>
            );
        };

        return (
            <div className="topicos" >
                <DataTable
                    stripedRows
                    showGridlines
                    ref={(el) => this.dt = el}
                    value={topicos}
                    columnResizeMode="fit"
                    header={header}
                    emptyMessage="sem tópicos cadastrados!" >
                    <Column style={{ width: '25%' }} header="Topico" filter filterElement={filterTopico} field="topico" body={renderTopico} />
                    <Column style={{ width: '17.5%' }} header="Sentimento" field="sentimento" body={this.renderSentimento} filter filterElement={filterSentimento} />
                    <Column style={{ width: '17.5%' }} header="Abrangência" body={renderAbrangencia} />
                    <Column style={{ width: '15%' }} header="Ações" body={this.renderActionsTopico}></Column>
                </DataTable>
            </div>
        );
    }

    renderSentimento = (topico) => {
        return (
            <span className={`badge-topico ${topico.sentimento.toLowerCase()}`}>{topico.sentimento}</span>
        );
    }

    /**
     * Renderização por mapeamento de {questionario.gruposQuestoes} para uso como propriedade do componente {Steps.model} em {this.render()}
     * @returns 
     */
    renderOptionsDimensoes() {
        let metamodeloAvaliativo = this.state.metamodeloAvaliativo;
        return metamodeloAvaliativo.eixos.map(eixo => {
            return {
                label: (<div className="p-text-center p-text-uppercase">|EIXO {eixo.numero}|<br />{eixo.nome}</div>),
                code: eixo.numero,
                items: eixo.dimensoes.map(dimensao => {
                    let countFragilidades = this.state.painel.topicos.filter(topico => topico.sentimento === "FRAGILIDADE" && topico.dimensaoNumero === dimensao.numero).length;
                    let countPotencialidades = this.state.painel.topicos.filter(topico => topico.sentimento === "POTENCIALIDADE" && topico.dimensaoNumero === dimensao.numero).length;
                    return {
                        label: (
                            <div className="p-d-flex" /*data-pr-tooltip={dimensao.descricao}*/ >
                                <div className="p-col-8">
                                    |DIMENSÃO {dimensao.numero}| {dimensao.nome}
                                </div>
                                <div className="p-col-3 p-d-flex p-ai-center p-jc-between">
                                    <Tooltip target=".badge-topicos-count" />
                                    <i className="badge-topicos-count pi pi-thumbs-up p-overlay-badge p-pt-2 p-ml-1" data-pr-tooltip="potencialidades" data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1.5rem' }}>
                                        <Badge value={countPotencialidades} style={{ backgroundColor: 'var(--blue-300)' }} />
                                    </i>
                                    <i className="badge-topicos-count pi pi-thumbs-down p-overlay-badge p-pt-2 p-ml-3" data-pr-tooltip="fragilidades" data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '1.5rem' }}>
                                        <Badge value={countFragilidades} style={{ backgroundColor: 'var(--pink-300)' }} />
                                    </i>
                                </div>
                            </div>
                        ),
                        value: dimensao,
                    }
                })
            }
        });
    }


    /**
     * 
     * @param {*} questionario 
     * @returns 
     */
    renderActionsTopico(topico) {
        let renderButtonEditarTopico, renderEditarTomadaAcoesTopico, renderButtonRemover;
        renderButtonEditarTopico = (
            <Button className="p-button-rounded p-button-text p-button-icon-only p-button-plain" tooltipOptions={{ position: 'top' }} tooltip="Editar tópico" icon="pi pi-pencil" onClick={() => {
                let topicoEdit = JSON.parse(JSON.stringify(topico));
                topicoEdit.editIndex = this.state.painel.topicos.indexOf(topico);
                this.setState({ dialogEditarTopico: true, editTopico: topicoEdit });
            }} />
        );
        renderEditarTomadaAcoesTopico = (
            <Button className="p-button-rounded p-button-text p-button-icon-only p-button-plain p-button-success" tooltipOptions={{ position: 'top' }} tooltip="Gerenciar tomadas de ações deste tópico" icon="pi pi-bolt" onClick={() => {
                let topicoEdit = JSON.parse(JSON.stringify(topico));
                topicoEdit.editIndex = this.state.painel.topicos.indexOf(topico);
                this.setState({ dialogEditarTomadasAcoes: true, editTopico: topicoEdit });
            }} />
        );
        renderButtonRemover = (
            <Button className="p-button-rounded p-button-text p-button-icon-only p-button-plain p-button-danger" tooltipOptions={{ position: 'top' }} tooltip="Excluir tópico deste painel" icon="pi pi-trash" onClick={() => {
                let dialogFunction = () => {
                    this.state.painel.topicos = this.state.painel.topicos.filter(topicoRemanescentes => topicoRemanescentes !== topico);
                    this.setState({ painel: this.state.painel.topicos });
                };
                this.setState({ dialogConfirmar: true, dialogConfirmarFunction: dialogFunction });
            }} />
        );

        return (
            <span className="p-d-flex p-jc-between" >
                {renderButtonEditarTopico}
                {renderEditarTomadaAcoesTopico}
                {renderButtonRemover}
            </span>
        );

    }


    /**
     * Renderiza modal para aceite das condições e início do questionário.
     * @returns 
     */
    showDialogConcordanciaGerenciar() {
        const dialogName = 'dialogConcordar';
        const footer = (
            <div className="p-d-flex p-jc-between">
                <Button label="CONCORDO" icon="pi pi-check" onClick={() => {
                    this.setState({ [dialogName]: false });
                }} />

                <Route render={(props) => (
                    <Button className="p-button-danger" label="DISCORDO" icon="pi pi-times" onClick={() => {
                        this.setState({ [dialogName]: false });
                        props.history.goBack();
                    }} />
                )} />
            </div>
        );


        return (
            <Dialog closable={false} header="Você deseja confirmar o gerenciamento deste painel?" footer={footer} visible={this.state[dialogName]} style={{ width: '85vw' }} modal onHide={() => this.onHide(dialogName)} >
                <ol style={{ lineHeight: '1.5rem', listStylePosition: 'inside', display: 'flex', flexDirection: 'column' }}>
                    <li>Este painel poderá ser modificado até <b>{new Date(this.state.painel.terminoAplicacao).toLocaleString()}</b>.<br /></li>
                    <li>Ao prosseguir, você concorda em participar do processo avaliativo.<br /></li>
                    <li>Gerencie cada dimensão avaliativa deste painel, cadastrando tópicos (fragilidades e potencialidades) detectadas.<br /></li>
                    <li>Para cada potencialidade ou fragilidade cadastrada, especialmente sobre fragilidades, cadastre ações de melhorias.</li>
                </ol>
            </Dialog>
        );
    }


    /**
    * Renderiza modal para aceite das condições e início do questionário.
    * @returns 
    */
    showDialogEditarTopico() {
        const dialogName = 'dialogEditarTopico';
        let dialogTitle;

        //se edição é de um tópico existente
        if (this.state.editTopico.editIndex === false) {
            dialogTitle = 'Adicionar tópico';
        } else {
            dialogTitle = 'Editar tópico';
            //se é a edição de um novo tópico
        }

        const handleSalvarTopico = (topico) => {
            //deve ser adicionado no servidor
            topico.revisao = {
                data: new Date().toJSON(),
                usuario: "giuseppe.lima@ifpb.edu.br"
            };

            //deve ser adicionado no servidor
            if (topico.editIndex === false) {
                // console.log('adicionou')
                topico.eixoNumero = this.state.filtroEixos.numero;
                topico.dimensaoNumero = this.state.filtroDimensoes.numero;
                topico.acoes = [];
                topico.criacao = topico.revisao;
                this.state.painel.topicos.push(topico);
            } else {
                //editando tópico existente
                // console.log('editou')
                this.state.painel.topicos[topico.editIndex] = topico;
                //adicionando novo tópico
            }
            this.setState({ [dialogName]: false });
        }

        const footer = (
            <div className="p-d-flex p-jc-between p-mt-3">
                <Button type="submit" form="edit-topico" label="SALVAR" icon="pi pi-check" />
                <Button className="p-button-danger" label="CANCELAR" icon="pi pi-times" onClick={() => {
                    this.setState({ [dialogName]: false, editTopico: undefined });
                }} />
            </div>
        );

        const DROPDOWN_OPTIONS_ORIGEM = [
            { label: 'REIVINDICAÇÃO COMUNIDADE INTERNA', value: 'REIVINDICAÇÃO COMUNIDADE INTERNA' },
            { label: 'REIVINDICAÇÃO COMUNIDADE EXTERNA', value: 'REIVINDICAÇÃO COMUNIDADE EXTERNA' },
            { label: 'RESULTADO AVALIAÇÃO INTERNA', value: 'AVALIAÇÃO INTERNA' },
            { label: 'RESULTADO AVALIAÇÃO EXTERNA', value: 'AVALIAÇÃO EXTERNA' }
        ];

        const DROPDOWN_OPTIONS_SENTIMENTO = [
            { label: 'POTENCIALIDADE', value: 'POTENCIALIDADE' },
            { label: 'FRAGILIDADE', value: 'FRAGILIDADE' }
        ];

        const DROPDOWN_OPTIONS_NIVELORGANIZACIONAL = Object.entries(this.state.metamodeloAvaliativo.tiposNiveisOrganizacionais).map(tipoNivelOrganizacionalEntry => {
            return {
                label: tipoNivelOrganizacionalEntry[1], value: tipoNivelOrganizacionalEntry[1]
            }
        }).sort((a, b) => a.label.localeCompare(b.label));

        const DROPDOWN_OPTIONS_SEGMENTOS = Object.entries(this.state.metamodeloAvaliativo.tiposSegmentos).map(tipoSegmentoEntry => {
            return {
                label: tipoSegmentoEntry[1], value: tipoSegmentoEntry[1]
            }
        }).sort((a, b) => a.label.localeCompare(b.label));



        return (
            <Dialog closable={false} header={dialogTitle} footer={footer} visible={this.state[dialogName]} style={{ width: '85vw' }} modal onHide={() => this.onHide(dialogName)} >
                <FormikFormTopico
                    id="edit-topico"
                    dropdownOptions={{ origem: DROPDOWN_OPTIONS_ORIGEM, sentimento: DROPDOWN_OPTIONS_SENTIMENTO, nivelOrganizacional: DROPDOWN_OPTIONS_NIVELORGANIZACIONAL, segmentos: DROPDOWN_OPTIONS_SEGMENTOS }}
                    handleSubmit={handleSalvarTopico}
                    topico={this.state.editTopico}
                />
            </Dialog>
        );
    }

    /**
   * Renderiza modal para aceite das condições e início do questionário.
   * @returns 
   */
    showDialogEditarTomadasAcoes() {
        const dialogName = 'dialogEditarTomadasAcoes';
        let dialogTitle = 'Editar tomadas de ações';

        const handleSalvarTopico = (topico) => {

            //deve ser adicionado no servidor
            topico.revisao = {
                data: new Date().toJSON(),
                usuario: "giuseppe.lima@ifpb.edu.br"
            };

            topico.acoes.forEach(acao => {
                if (!acao.revisao) {
                    console.log(acao.acao)
                    acao.revisao = {
                        data: new Date().toJSON(),
                        usuario: "giuseppe.lima@ifpb.edu.br"
                    }
                }
            })
            // console.log(topico)

            this.state.painel.topicos[topico.editIndex] = topico;
            this.setState({ [dialogName]: false });
        }

        const footer = (
            <div className="p-d-flex p-jc-between p-mt-3">
                <Button type="submit" form="edit-tomadasacoes" label="SALVAR" icon="pi pi-check" />
                <Button className="p-button-danger" label="CANCELAR" icon="pi pi-times" onClick={() => {
                    this.setState({ [dialogName]: false, editTopico: undefined });
                }} />
            </div>
        );

        const DROPDOWN_OPTIONS_AVALIACAO = this.state.metamodeloAvaliativo.escalaAvaliativa.map((itemEscalaAvaliativaEntry, index) => {
            return {
                label: itemEscalaAvaliativaEntry.valorQualitativo, value: index
            }
        });

        const headerTomadasAcoes = (
            <div className="p-field p-fluid">
                <label className="p-d-block">TÓPICO</label>
                <div className="p-mb-1">{this.renderSentimento(this.state.editTopico)} </div>
                <div><InputTextarea autoResize disabled value={this.state.editTopico.topico} /></div>
            </div>
        );

        return (
            <Dialog closable={false} header={dialogTitle} footer={footer} visible={this.state[dialogName]} style={{ width: '90vw' }} modal onHide={() => this.onHide(dialogName)} >
                <FormikFormTomadasAcoes
                    id="edit-tomadasacoes"
                    header={headerTomadasAcoes}
                    dropdownOptions={{ avaliacao: DROPDOWN_OPTIONS_AVALIACAO }}
                    handleSubmit={handleSalvarTopico}
                    topico={this.state.editTopico}
                />
            </Dialog>
        );
    }

    /**
     * Renderiza modal para aceite das condições e início do questionário.
     * @returns 
     */
    showDialogConfirmacao() {
        const dialogName = 'dialogConfirmar';
        const footer = (
            <div className="p-d-flex p-jc-between p-mt-3">
                <Button label="CONFIRMAR" icon="pi pi-check" onClick={() => {
                    this.state.dialogConfirmarFunction();
                    this.setState({ [dialogName]: false, dialogConfirmarFunction: undefined });
                }} />

                <Route render={(props) => (
                    <Button className="p-button-danger" label="CANCELAR" icon="pi pi-times" onClick={() => {
                        this.setState({ [dialogName]: false });
                    }} />
                )} />
            </div>
        );


        return (
            <Dialog closable={false} header="Você deseja confirmar esta operação?" footer={footer} visible={this.state[dialogName]} style={{ width: '85vw' }} modal onHide={() => this.onHide(dialogName)} >

            </Dialog>
        );
    }

    // /**
    //  * * @param {*} topico 
    //  * @returns true - se tópico possui campos com valores válidos
    //  */

    // validarObjeto(objeto, CAMPOS_OBRIGATORIOS) {
    //     let invalidos = [];

    //     CAMPOS_OBRIGATORIOS.forEach(campoObrigatorio => {
    //         let campoValor = campoObrigatorio.split('.').reduce((valorCampo, campoSecundario) => {
    //             // console.log(valorCampo, campoSecundario)
    //             return valorCampo[campoSecundario];
    //         }, objeto);
    //         // console.log(campoObrigatorio, campoValor);
    //         // console.log((true && campoValor ) );
    //         if (true && campoValor && !campoValor.length || !campoValor) {
    //             invalidos.push(campoObrigatorio);
    //         }
    //     });
    //     // console.log(invalidos);
    //     return invalidos;
    // }

}
