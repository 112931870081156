import React from "react";

function VideoDrive(props) {
  const urlDrive = `https://drive.google.com/uc?export=download&id=${props.driveFileId}`;
  return (
    <div className="p-d-flex" style={{ height: '100%', width: '100%' }}>
      <video id="videoajuda" style={{ height: '100%' }} className="p-col-12" controls="controls" autoPlay={(!props.autoPlay)? true : props.autoPlay}>
        <source src={urlDrive} type='video/mp4' />
      </video>
    </div>
  );
}

export default VideoDrive;