// {
//   escala: [
//     {
//       valorQuantitativo: 0,
//       valorQualitativo: "PRÉ-INDICADA",
//       descricao: "Quando, no ciclo avaliativo, a ação de melhoria foi APENAS CADASTRADA, mais ainda não é monitorável para fins de avaliação."
//     },
//     {
//       valorQuantitativo: 1,
//       valorQualitativo: "EM IMPLANTAÇÃO",
//       descricao: "Quando, no ciclo avaliativo, a ação de melhoria está MONITORADA e EM ANDAMENTO, sob supervisão do nível organizacional (NDE, cood. curso, ou direções)"
//     },
//     {
//       valorQuantitativo: 2,
//       valorQualitativo: "NÃO IMPLANTADA",
//       descricao: "Quando, no ciclo avaliativo, a ação de melhoria MONITORADA foi CANCELADA pelo nível organizacional (NDE, coord. curso, ou direções), justificável por impedimentos."
//     },
//     {
//       valorQuantitativo: 2,
//       valorQualitativo: "IMPLANTADA PARCIALMENTE",
//       descricao: "Quando, no ciclo avaliativo, a ação de melhoria MONITORADA foi FINALIZADA EM PARTE pelo nível organizacional (NDE, coord. curso, ou direções), justificável por impedimentos."
//     },
//     {
//       valorQuantitativo: 3,
//       valorQualitativo: "IMPLANTADA TOTALMENTE",
//       descricao: "Quando, no ciclo avaliativo, a ação de melhoria MONITORADA foi FINALIZADA pelo nível organizacional (NDE, coord. curso, ou direções), sem quaisquer restrições."
//     }
//   ],
export const paineisApropriadores = [
    {
        id: 'dsdsd',
        inicioAplicacao: "2021-07-02T00:00:00-0300",
        terminoAplicacao: "2021-09-30T23:59:00-0300",
        // segmento: "DOCENTE", //segmento autor
        nivelOrganizacional: {
            "CAMPUS": "CAMPUS-MT",
            "CURSO": "516 - Curso Superior de Tecnologia em Análise e Desenvolvimento de Sistemas - Monteiro (CAMPUS MONTEIRO)"
        },
        topicos: [
            {
                topico: "Recepção de eventos do curso",
                origem: "REIVINDICAÇÃO COMUNIDADE INTERNA",
                sentimento: "POTENCIALIDADE", //POTENCIALIDADE
                eixoNumero: 3,
                dimensaoNumero: 2,
                contexto: {
                    nivelOrganizacional: "CURSO",
                    segmentos: ["DISCENTE", "DOCENTE"] 
                },
                criacao: {
                    data: "2021-07-02T00:00:00-0300",
                    usuario: "giuseppe.lima@ifpb.edu.br"
                },
                revisao: {
                    data: "2021-07-02T00:00:00-0300",
                    usuario: "giuseppe.lima@ifpb.edu.br"
                },
                acoes: [
                    {
                        acao: 'Divulgar no portal de notícias do campus',
                        revisao: {
                            data: "2021-07-02T00:00:00-0300",
                            usuario: "giuseppe.lima@ifpb.edu.br"
                        },
                        avaliacao: 0,
                        impedimentos: [
                            'impd1 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo',
                            'impd2 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo',
                            'impd3 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo'
                        ],
                        comprovacoes: [
                            'comp1 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo',
                            'comp2 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo',
                            'comp3 enorme de grande muito texto pra valer mesmo é isso q esta acontecendo'
                        ]
                    },
                    {
                        acao: '2 Divulgar no portal de notícias do campus',
                        revisao: {
                            data: "2021-07-02T00:00:00-0300",
                            usuario: "giuseppe.lima@ifpb.edu.br"
                        },
                        avaliacao: 0,
                        impedimentos: [

                        ],
                        comprovacoes: [

                        ]
                    },
                    {
                        acao: '3 Divulgar no portal de notícias do campus',
                        revisao: {
                            data: "2021-07-02T00:00:00-0300",
                            usuario: "giuseppe.lima@ifpb.edu.br"
                        },
                        avaliacao: 0,
                        impedimentos: [

                        ],
                        comprovacoes: [

                        ]
                    }
                ]
            },
            {
                topico: "Oportunidades do estágio",
                origem: "REIVINDICAÇÃO", //AVALIAÇÃO INERNA //AVALIAÇÃO EXTERNA
                sentimento: "FRAGILIDADE", //POTENCIALIDADE
                eixoNumero: 3,
                dimensaoNumero: 4,
                contexto: {
                    nivelOrganizacional: "INSTITUCIONAL",//INSTITUCIONAL, CAMPUS, CURSO, DISCIPLINA, SETOR
                    segmentos: ["DISCENTE", "DOCENTE"] //DISCENTE, DOCENTE, TECNICO_ADMINITRATIVO
                },
                criacao: {
                    data: "2021-07-02T00:00:00-0300",
                    usuario: "giuseppe.lima@ifpb.edu.br"
                },
                revisao: {
                    data: "2021-07-02T00:00:00-0300",
                    usuario: "giuseppe.lima@ifpb.edu.br"
                }
            }
        ]
    }
]