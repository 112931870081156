import React, { useState, Component } from "react";
import { Route } from 'react-router-dom'

import Ifpb from "../../assets/styles/imgs/ifpb.svg";
import Avin from "../../assets/styles/imgs/avin.svg";
import VideoDrive from "../VideoDrive";

import "./styles.css";

import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from 'primereact/dialog';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        usuario: undefined,
        videoajudaDialog: false
    }

    // componentWillReceiveProps(props) {
    //     if (props.usuario !== this.state.usuario) {
    //         this.setState({ usuario: props.usuario });
    //     }
    // }

    static getDerivedStateFromProps(props, state) {
        // Store prevId in state so we can compare when props change.
        // Clear out previously-loaded data (so we don't render stale stuff).
        if (props.usuario !== state.usuario) {
            return {
                usuario: props.usuario
                // externalData: null,
                // prevId: props.id,
            };
        }
        // No state update necessary
        return null;
    }

    componentDidMount() {
        // this.setState({usuario: this.props.usuario});
    }

    leftContents = (
        <div id="header-leftContents">
            <Button onClick={() => { this.setState({ videoajudaDialog: true }) }}>
                <i className="pi pi pi-question-circle" />
            </Button>
            {/* <Button>
                <i className="pi pi-bars p-toolbar-separator p-mr-2" />
            </Button> */}
            {/* <Route render={(props) => (
                    <Button onClick={() => {
                            
                        }}>
                        <i className="pi pi-sign-out" />                    
                    </Button>
             )} /> */}
        </div>
    );

    rightContents = () => {
        let renderEmail = '';
        return (
            <div id="logo-bar">
                <span id="usuario">{(this.state.usuario) ? this.state.usuario.email : null}</span>
                <img id="ifpb" src={Ifpb}></img>
                <Route render={(props) => (
                    <Button onClick={(e) => { props.history.replace({ pathname: `/` }) }}><img id="avin" src={Avin} ></img></Button>
                )} />
            </div>
        );
    };


    render() {
        let renderToolbar = (this.state.usuario && this.state.usuario.email) ? <Toolbar id="Toolbar" left={this.leftContents} right={this.rightContents()}></Toolbar> : null;

        return (
            <div id="header" >
                {this.showDialogVideoajuda()}
                {renderToolbar}
            </div>
        );
    }

    showDialogVideoajuda() {
        const urlDrive = `https://drive.google.com/uc?export=download&id=${this.props.videoajuda}` //`https://drive.google.com/file/d/${this.props.videoajuda}/preview`;
        const dialogName = 'videoajudaDialog';
        const footer = (
            <div>
                {/* <Button label="FECHAR" onClick={() => {
                    this.setState({ [dialogName]: false });
                }} /> */}

                {/* <Route render={(props) => (
                    <Button className="p-button-danger" label="Discordo em participar" icon="pi pi-times" onClick={() => {
                        this.setState({ [dialogName]: false });
                        this.setState({ etapa: 'I' });
                        props.history.goBack();
                    }} />
                )} /> */}
            </div>
        );


        return (
            <Dialog closable={true} header="VIDEOAJUDA" footer={footer} visible={this.state[dialogName]} style={{ width: '85vw', height: '85vw' }} modal onHide={() => { this.setState({ [dialogName]: false }) }} >
                <div className="p-d-flex" style={{ height: '100%', width: '100%' }}>
                    <VideoDrive driveFileId={this.props.videoajuda} />
                </div>
            </Dialog>
        );
    }
}




