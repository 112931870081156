import React, {useContext} from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import { AppProvider } from "./AppProvider.js";

import TelaInicial from "./pages/TelaInicial";
import TelaQuestionarios from "./pages/TelaQuestionarios";
import TelaQuestionario from "./pages/TelaQuestionario";
import TelaPaineisApropriadores from "./pages/TelaPaineisApropriadores";
import TelaPainelApropriador from "./pages/TelaPainelApropriador";
import TelaLogin from "./pages/TelaLogin/index.js";

//https://medium.com/trabe/implementing-private-routes-with-react-router-and-hooks-ed38d0cf93d5
/**
 * @description - Verifica se há algum usuário autenticado (Google GAS) e autorizado, caso contrário apresentar {TelaLogin}
 * @param {idVideoajudaYoutube} - id obtida arquivo de vídeo compartilhado publicamente pelo GoogleDrive. Repassa como property esse valor para páginas, para ser usado em componentes de videoajuda (ex.: {Header}). 
 * @returns 
 */
const PrivateRoute = ({component, videoajuda, ...props}) => {
    const {state} = useContext(AppProvider.Context);
    // console.log(state);
    const componenteAutorizado = (state.usuario && state.usuario.autorizado)? component : TelaLogin; 

    //return <Route {...props} component={componenteAutorizado} />;
    return <Route {...props} render={() => React.createElement(componenteAutorizado, {videoajuda})} />;
};

function Routes() {
    return (
        <BrowserRouter basename="/avin">
            <PrivateRoute exact path="/" component={TelaInicial} videoajuda="1BjMW3UDABZw0RSJxj2Lxtu6XpnwkqQxy" />
            <PrivateRoute exact path="/questionarios" component={TelaQuestionarios} videoajuda="1oEIWAkprRvSwiSGkVVSpy0IrJtFGLg9C" />
            <PrivateRoute path="/questionario/:id" component={TelaQuestionario} videoajuda="1Wfc29HJeQFbyYYiGyIkJwphrOsuROwL_" />

            <PrivateRoute exact path="/paineisapropriadores/" component={TelaPaineisApropriadores} />
            <PrivateRoute path="/painelapropriador/:id" component={TelaPainelApropriador} />

        </BrowserRouter>
    );
}
export default Routes;