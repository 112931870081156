import React from "react";

import Header from "../../components/Header";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";

import "./styles.css";

import { AppProvider } from "../../AppProvider";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Questionarios from "../../components/Questionarios";

const ICON_SIZE = '2em';

function TelaInicial(props) {
  const history = useHistory();

  return (
    <AppProvider.Context.Consumer>
      {(context) => (
        <div className="inicial">
          <Header videoajuda={props.videoajuda} usuario={context.state.usuario} />
          <div className="p-d-flex p-dir-col p-mr-1 p-ml-1 p-justify-end">
            {renderButtonQuestionarios(context.state.questionarios, history)}
            {/*renderButtonPaineisApropriadores(context.state.usuario, history)*/}
          </div>

          {/* <Questionarios disponiveis="true" respondidos="true" questionarios={context.state.questionarios} /> */}
        </div>
      )}
    </AppProvider.Context.Consumer>
  );
}

function renderButtonQuestionarios(questionarios, history) {
  let count = 0;
  if (questionarios !== undefined) {
    count = questionarios.filter(questionario => !Questionarios.isRespondido(questionario));
    if (count !== undefined) {
      count = count.length;
    }
  }
  return (
    <Button className="p-col p-mb-2 p-button-lg"
      label="QUESTIONÁRIOS"
      icon={<i className="pi pi-question" style={{ 'fontSize': ICON_SIZE }} />}
      badge={count}
      tooltip={(count > 0)? `Há ${count} questionário(s) para responder.`: null}
      tooltipOptions={{position: 'top'}}
      onClick={() => { history.push('/questionarios') }} />
  );
}

function renderButtonPaineisApropriadores(usuario, history) {
  //se usuário não for gestor
  // if (usuario && usuario.niveisOrganizacionais && usuario.niveisOrganizacionais.GESTOR)
    return (
      <Button className="p-col p-mb-2 p-button-lg"
        label="PAINEIS APROPRIADORES"
        icon={<i className="pi pi-sitemap" style={{ 'fontSize': ICON_SIZE }} />}
        // badge={count}
        onClick={() => { history.push('/paineisapropriadores') }} />
    );
}

export default TelaInicial;