export const AppConfig = {
    //CASO TRUE e for um novo projeto back-end AppsScript, realizar ao menos uma primeira implantação para liberar acesso DEV.
    DEV_MODE: false,    

    //GAS WEBAPP PRODUÇÃO (PASTA AVIN DA CPA 2022.2)
    // GAS_URL_DEV: "https://script.google.com/a/macros/ifpb.edu.br/s/AKfycbz5pv6J0_y8_73cAJu7882ffeFZOvk7-dHhqC8jcMo/dev?dev=true",
    GAS_URL_EXEC: "https://script.google.com/a/macros/ifpb.edu.br/s/AKfycbzXqwtZi5vUCwhq9YSszArJyHiBBE702IMSLReD3FpUgNpyR8TMi1z81m5peJSzZHtHew/exec?exec=true",

    //GAS WEBAPP DEVELOPMENT (PASTA AVIN DESENVOLVIMENTO)
    // GAS_URL_DEV: "https://script.google.com/a/macros/ifpb.edu.br/s/AKfycbyPTaAUNVB6pLca6Fyd8tIOJ1z36bUgHIf6QUUDkQ8/dev?dev=true",
    // GAS_URL_EXEC: "https://script.google.com/a/macros/ifpb.edu.br/s/AKfycbx4ZsiCpMQNMfvcUJzFfxX4-xWzeRyjPEvEQ2MYJRv9p8VjfKq00iwIzp-zPtBHALAA/exec?exec=true",

    //DEMAIS PARÂMETROS
    GAS_URL_ACCOUNT_CHOOSER: "https://accounts.google.com/AccountChooser/signinchooser?flowName=GlifWebSignIn&flowEntry=AccountChooser&continue=",
    CPA_EMAIL: 'avaliacao@ifpb.edu.br',
    CPA_URL: 'https://ifpb.edu.br/cpa'
}

AppConfig.GAS_URL_DEV += encodeURIComponent(`&urlUI=${window.location.origin}`);
AppConfig.GAS_URL_EXEC += encodeURIComponent(`&urlUI=${window.location.origin}`);

